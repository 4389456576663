<template>
	<div class="row">
		<div class="col-12">
			<div class="loading padding">
			
				<svg version="1.2" height="300" width="600" xmlns="http://www.w3.org/2000/svg" viewport="0 0 60 60"
					xmlns:xlink="http://www.w3.org/1999/xlink">
			
					<path id="pulsar" stroke="#6eadd2" fill="none" stroke-width="1" stroke-linejoin="round"
						d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210" />
			
				</svg>
			
			</div>
		</div>
	</div>
  
</template>

<script>
export default {
  name: 'Loding',
};
</script>
<style scoped>


	
.loading {
	
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  top: 36vh;
  margin-bottom: 60vh; 
}
.padding{
	padding: 0 5%;
}
@media(max-width:786px){
	.padding{
	padding: 0 0%;
}
}
.loading svg {
  width: 100%;
  height: 100%;
}

.loading #pulsar{
  stroke-dasharray:281;
  -webkit-animation: dash 2.5s infinite linear forwards;
}

/*Animation*/
@-webkit-keyframes dash{ from{stroke-dashoffset:814;} to {stroke-dashoffset:-814;}}
</style>



<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto:100');
body {
  background: #e6feff;
}

/** page **/
.cssloader {
  padding-top: calc(45vh - 100px);
  padding-bottom: calc(45vh - 22%);
}
@media (min-width:768px){
.cssloader{
  padding-bottom: calc(45vh - 28%);
}
}
/** loader **/
.sh1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #cc0070  transparent transparent transparent;
  margin: 0 auto;
  animation: shk1 1s ease-in-out infinite normal;
}

.sh2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent  transparent #cc0070  transparent ;
  margin: -50px auto 0;
  animation: shk2 1s ease-in-out infinite alternate;
}

/** animation starts here **/
@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }  
  
  100% {
  }
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
  }
}

.lt {
  color: #026097;
  font-family: 'Cairo', sans-serif;
  margin: 30px auto;
  text-align: center;
  font-weight: 100;
  
}




.pl {
	display: block;
	width: 8em;
	height: 8em;
  margin-right: 48vw;
/* margin-bottom: 2vh; */

}
.pl__ring,
.pl__ball1,
.pl__ball2 {
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	transform-origin: 32px 32px;
}
.pl__ring {
	animation-name: ring;
}
.pl__ball1 {
	animation-name: ball1;
}
.pl__ball2 {
	animation-name: ball2;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),10%,10%);
		--fg: hsl(var(--hue),10%,90%);
	}
}

/* Animation */
@keyframes ring {
	from {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: -122.52;
		transform: rotate(135deg);
	}
	15% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -122.52;
		transform: rotate(90deg);
	}
	35% {
		animation-timing-function: ease-out;
		stroke-dashoffset: -65.34;
		transform: rotate(297.5deg);
	}
	55% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: -122.52;
		transform: rotate(505deg);
	}
	70% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: -122.52;
		transform: rotate(490deg);
	}
	85%,
	to {
		stroke-dashoffset: -122.52;
		transform: rotate(497.5deg);
	}
}
@keyframes ball1 {
	from {
		transform: rotate(14deg);
	}
	20% {
		transform: rotate(-7deg);
	}
	60% {
		transform: rotate(399deg);
	}
	75% {
		transform: rotate(361deg);
	}
	90%,
	to {
		transform: rotate(374deg);
	}
}
@keyframes ball2 {
	from {
		transform: rotate(-21deg);
	}
	25% {
		transform: rotate(-47deg);
	}
	60% {
		transform: rotate(364deg);
	}
	75% {
		transform: rotate(326deg);
	}
	90%,
	to {
		transform: rotate(339deg);
	}
}
@media(max-width:786px){
  .pl{
    margin-right: 32vw;
    margin-bottom: 2vh;
  }
}
</style>

