<template>
  <!-- header (relative style) -->
  <header class="header header--static">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="header__content">
            <button class="header__menu" type="button">
              <span></span>
              <span></span>
              <span></span>
            </button>
            <router-link to="/" class="header__logo" style="margin-right: 20px;">
              <img src="../../assets/MAdvisor.png" alt="" />
              <!-- <h5 class="">Medical Advisor</h5> -->
              <!-- <h1 class="" style="color: rgb(255, 255, 255);font-size: 1.8rem;font-weight: bold;">M Advisor</h1>  -->
            </router-link>
            <ul class="header__nav">
              <li class="header__nav-item">
                <router-link to="/" class="header__nav-link" role="button" id="dropdownMenu2" aria-expanded="false">
                  <svg class="svg-icon" style="margin-right: 0px; width: 17px; margin-left: 5px" viewBox="0 0 20 20">
                    <path fill="#fff" d="M13.22,2.984c-1.125,0-2.504,0.377-3.53,1.182C8.756,3.441,7.502,2.984,6.28,2.984c-2.6,0-4.714,2.116-4.714,4.716c0,0.32,0.032,0.644,0.098,0.96c0.799,4.202,6.781,7.792,7.46,8.188c0.193,0.111,0.41,0.168,0.627,0.168c0.187,0,0.376-0.041,0.55-0.127c0.011-0.006,1.349-0.689,2.91-1.865c0.021-0.016,0.043-0.031,0.061-0.043c0.021-0.016,0.045-0.033,0.064-0.053c3.012-2.309,4.6-4.805,4.6-7.229C17.935,5.1,15.819,2.984,13.22,2.984z M12.544,13.966c-0.004,0.004-0.018,0.014-0.021,0.018s-0.018,0.012-0.023,0.016c-1.423,1.076-2.674,1.734-2.749,1.771c0,0-6.146-3.576-6.866-7.363C2.837,8.178,2.811,7.942,2.811,7.7c0-1.917,1.554-3.47,3.469-3.47c1.302,0,2.836,0.736,3.431,1.794c0.577-1.121,2.161-1.794,3.509-1.794c1.914,0,3.469,1.553,3.469,3.47C16.688,10.249,14.474,12.495,12.544,13.966z"></path>                  </svg>
                 الرئيسية
                </router-link>
              </li>
              <li class="header__nav-item">
                <a class="dropdown-item" href="#" role="button" id="dropdownMenuSub" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg class="svg-icon" viewBox="0 0 20 20" style="margin-right: 0px; width: 17px; margin-left: 5px">
                    <path fill="#fff" d="M5.029,1.734h10.935c0.317,0,0.575-0.257,0.575-0.575s-0.258-0.576-0.575-0.576H5.029
								c-0.318,0-0.576,0.258-0.576,0.576S4.711,1.734,5.029,1.734z M1,5.188V19h18.417V5.188H1z M18.266,17.849H2.151V6.338h16.115
								V17.849z M2.727,4.036H17.69c0.317,0,0.575-0.257,0.575-0.576c0-0.318-0.258-0.575-0.575-0.575H2.727
								c-0.318,0-0.576,0.257-0.576,0.575C2.151,3.779,2.409,4.036,2.727,4.036z"></path>
                  </svg> الأقسام
                </a>
                <ul class="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenuSub">
                  <li class="header__nav-item" v-for="publish in Categorys" :key="publish.id">
                    <router-link :to="{name:'Category',params:{id:publish.id}}" class="header__nav-link" >{{publish.name_ar}}</router-link>
                  </li>
                  <!-- <li class="header__nav-item">
                    <router-link to="/Ab3adSocial" class="header__nav-link" >شباب</router-link>
                  </li>
                  <li class="header__nav-item">
                    <router-link to="/Ab3adSheria" class="header__nav-link" >كبار سن</router-link>
                  </li> -->
                  <!-- <li class="header__nav-item">
                    <router-link to="/AllAboutWomen" class="header__nav-link">سايكولوجي</router-link>
                  </li> -->
                  
                </ul>
              </li>
              <template v-if="showHeader">
              <li class="header__nav-item">
                <!-- <a  class="dropdown-item" href="#" role="button" id="dropdownMenuSub" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg class="svg-icon" style="margin-right: 0px;width: 17px;margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path  style="stroke: #fff; fill: #fff" d="M20,12a1,1,0,0,0-1-1H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H19A1,1,0,0,0,20,12ZM17,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V16a1,1,0,0,0-2,0v3a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V5A3,3,0,0,0,17,2Z"
                        ></path>
                      </svg>
                      <router-link to="/Unsubscribes"  style="color:#fff">
                      إلغاء الإشتراك
                    </router-link>
                </a> -->
              </li>
              </template>
              <template v-else>
              <li class="header__nav-item" >
                <router-link to="/Login" class="header__nav-link"  role="button" id="dropdownMenu2" aria-expanded="false" >
                  <svg class="svg-icon" viewBox="0 0 20 20" style="margin-right: 0px;width: 17px;margin-left: 5px;">
                    <path fill="#fff" d="M12.443,9.672c0.203-0.496,0.329-1.052,0.329-1.652c0-1.969-1.241-3.565-2.772-3.565S7.228,6.051,7.228,8.02c0,0.599,0.126,1.156,0.33,1.652c-1.379,0.555-2.31,1.553-2.31,2.704c0,1.75,2.128,3.169,4.753,3.169c2.624,0,4.753-1.419,4.753-3.169C14.753,11.225,13.821,10.227,12.443,9.672z M10,5.247c1.094,0,1.98,1.242,1.98,2.773c0,1.531-0.887,2.772-1.98,2.772S8.02,9.551,8.02,8.02C8.02,6.489,8.906,5.247,10,5.247z M10,14.753c-2.187,0-3.96-1.063-3.96-2.377c0-0.854,0.757-1.596,1.885-2.015c0.508,0.745,1.245,1.224,2.076,1.224s1.567-0.479,2.076-1.224c1.127,0.418,1.885,1.162,1.885,2.015C13.961,13.689,12.188,14.753,10,14.753z M10,0.891c-5.031,0-9.109,4.079-9.109,9.109c0,5.031,4.079,9.109,9.109,9.109c5.031,0,9.109-4.078,9.109-9.109C19.109,4.969,15.031,0.891,10,0.891z M10,18.317c-4.593,0-8.317-3.725-8.317-8.317c0-4.593,3.724-8.317,8.317-8.317c4.593,0,8.317,3.724,8.317,8.317C18.317,14.593,14.593,18.317,10,18.317z"></path>						      </svg>
                   دخول
                </router-link>
              </li>
              <li class="header__nav-item" >
                <a @click="Subscrib()" class="header__nav-link" role="button" id="dropdownMenu2" aria-expanded="false">
                  <svg class="svg-icon" viewBox="0 0 20 20" style="margin-right: 0px;width: 17px;margin-left: 5px;">
                    <path fill="#fff" d="M17.72,5.011H8.026c-0.271,0-0.49,0.219-0.49,0.489c0,0.271,0.219,0.489,0.49,0.489h8.962l-1.979,4.773H6.763L4.935,5.343C4.926,5.316,4.897,5.309,4.884,5.286c-0.011-0.024,0-0.051-0.017-0.074C4.833,5.166,4.025,4.081,2.33,3.908C2.068,3.883,1.822,4.075,1.795,4.344C1.767,4.612,1.962,4.853,2.231,4.88c1.143,0.118,1.703,0.738,1.808,0.866l1.91,5.661c0.066,0.199,0.252,0.333,0.463,0.333h8.924c0.116,0,0.22-0.053,0.308-0.128c0.027-0.023,0.042-0.048,0.063-0.076c0.026-0.034,0.063-0.058,0.08-0.099l2.384-5.75c0.062-0.151,0.046-0.323-0.045-0.458C18.036,5.092,17.883,5.011,17.72,5.011z"></path>
							<path fill="none" d="M8.251,12.386c-1.023,0-1.856,0.834-1.856,1.856s0.833,1.853,1.856,1.853c1.021,0,1.853-0.83,1.853-1.853S9.273,12.386,8.251,12.386z M8.251,15.116c-0.484,0-0.877-0.393-0.877-0.874c0-0.484,0.394-0.878,0.877-0.878c0.482,0,0.875,0.394,0.875,0.878C9.126,14.724,8.733,15.116,8.251,15.116z"></path>
							<path fill="none" d="M13.972,12.386c-1.022,0-1.855,0.834-1.855,1.856s0.833,1.853,1.855,1.853s1.854-0.83,1.854-1.853S14.994,12.386,13.972,12.386z M13.972,15.116c-0.484,0-0.878-0.393-0.878-0.874c0-0.484,0.394-0.878,0.878-0.878c0.482,0,0.875,0.394,0.875,0.878C14.847,14.724,14.454,15.116,13.972,15.116z"></path></svg>
                  إشتــرك الأن
                </a>
              </li>
              </template>
            </ul>
            <div class="header__actions" style="margin-left: 0px">
              <form  @submit.prevent="SearchData()" class="header__form">
                <input
                  class="header__form-input"
                  v-model="search"
                  type="text"
                  placeholder="بحث ..."
                />
                <button class="header__form-btn" type="submit">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"
                    />
                  </svg>
                </button>
                <button type="button" class="header__form-close">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.3345 0.000183105H5.66549C2.26791 0.000183105 0.000488281 2.43278 0.000488281 5.91618V14.0842C0.000488281 17.5709 2.26186 20.0002 5.66549 20.0002H14.3335C17.7381 20.0002 20.0005 17.5709 20.0005 14.0842V5.91618C20.0005 2.42969 17.7383 0.000183105 14.3345 0.000183105ZM5.66549 1.50018H14.3345C16.885 1.50018 18.5005 3.23515 18.5005 5.91618V14.0842C18.5005 16.7653 16.8849 18.5002 14.3335 18.5002H5.66549C3.11525 18.5002 1.50049 16.7655 1.50049 14.0842V5.91618C1.50049 3.23856 3.12083 1.50018 5.66549 1.50018ZM7.07071 7.0624C7.33701 6.79616 7.75367 6.772 8.04726 6.98988L8.13137 7.06251L9.99909 8.93062L11.8652 7.06455C12.1581 6.77166 12.6329 6.77166 12.9258 7.06455C13.1921 7.33082 13.2163 7.74748 12.9984 8.04109L12.9258 8.12521L11.0596 9.99139L12.9274 11.8595C13.2202 12.1524 13.2202 12.6273 12.9273 12.9202C12.661 13.1864 12.2443 13.2106 11.9507 12.9927L11.8666 12.9201L9.99898 11.052L8.13382 12.9172C7.84093 13.2101 7.36605 13.2101 7.07316 12.9172C6.80689 12.6509 6.78269 12.2343 7.00054 11.9407L7.07316 11.8566L8.93843 9.99128L7.0706 8.12306C6.77774 7.83013 6.77779 7.35526 7.07071 7.0624Z"
                    />
                  </svg>
                </button>
              </form>
              <button class="header__search" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"
                  />
                </svg>
              </button>
              <template v-if="showHeader">
                  <a @click="Logout()" class="header__logo sign__btn pointer" style="margin-right: 15px;height: 2.5rem;width: 150px;padding-left: 0;">
                      <span class="">خروج</span>
                  </a>
                  <router-link to="/Unsubscribes" class="header__logo sign__btn" style="margin-right: 10px; height: 2.5rem; width: 100%; padding-left: 0px;">
                      <span class="">الغاء الاشتراك</span>
                  </router-link>
              </template>
              <template v-else>
                  <router-link to="/Login" class="header__logo sign__btn1" style="margin-right: 15px;height: 2.5rem;width: 60px;padding-left: 0;">
                      <span class="">دخول</span>
                  </router-link>
                  <a @click="Subscrib()" class="header__logo sign__btn1 pointer" style="margin-right: 15px;height: 2.5rem;width: 60px;padding-left: 0;">
                      <span class="">اشتراك</span>
                  </a>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- end header -->
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { HTTPSPAY } from '@/Api/http-spay';
import { ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import { useToast } from "vue-toastification";
export default {
  name: "Header",
  setup() {
        const router = useRouter();
        const cookie = useCookie()
        const toast = useToast();
        const showHeader = ref(true);
        const Categorys = ref([]);
        const search = ref([]);

        try {
           HTTP.get(`GetAllCategories.php`).then((res) => {
            Categorys.value = res.data.Categories; 
          });
      } catch (err) {
          console.log(err);
      }
         
        watchEffect(() => {
            if (cookie.isCookieAvailable("status") && cookie.isCookieAvailable("msisdn")) {
                HTTPSPAY.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+ `&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status === 1 ) {
                        return;
                    } else {
                        cookie.removeCookie("MINTUES");
                        cookie.removeCookie("msisdn");
                        cookie.removeCookie("status");
                    }
                });
                showHeader.value = true;
            } else {
               showHeader.value = false;
            }
        });
        
        const Subscrib = () => {
            window.open("http://sudanipay.com/subscription/si7atk/si7atk", "_self");
        }

        const Logout = () => {
            toast.success('تم تسجيل الخروج', { timeout: 2500, });
			      cookie.removeCookie("MINTUES");
            cookie.removeCookie("msisdn");
            cookie.removeCookie("status");
			      setTimeout(() => router.push({ path: "/" }), 2500);
			      setTimeout(() => location.reload(), 2500);
        }

        const SearchData = () => {
          router.push({ name: "Search", params: { search_id: search.value } });
        }
        return { Categorys, Subscrib ,showHeader, SearchData, search, Logout};
    },
    
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "/assets/js/main.js");
    document.head.appendChild(externalScript);
  },
};
</script>

<style scoped>
a:hover {
  color: #0056b3;
}
.header__nav-menu a:hover {
  color: #179C8C;
}
.pointer {
  cursor: pointer;
}
.sign__btn_sub {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px auto;
    width: 100%;
    height: 50px;
    border-radius: 16px;
    background-image: linear-gradient(0deg,#026097 0,#71C6F7 );
    font-size: 18px;
    color: #e0e0e0;
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Cairo', sans-serif;
}

a:hover {
  color: #179C8C;
}

/* @media (min-width: 768px) { */
    .sign__btn1 {
        display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin: 15px auto;
width: 100%;
height: 50px;
border-radius: 10px;
font-size: 15px;
color: #fffefe;
text-transform: uppercase;
font-weight: 600;
font-family: 'Noto Kufi Arabic', sans-serif !important;
background-image: linear-gradient(0deg,#2FAC9E 0,#A6D2CD );
    }
/* } */
@media (min-width: 1200px) {
    .sign__btn1 {
        display: none;    }
}
</style>