<template>
          <!-- footer -->
    <center>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer__content">
                            <div class="footer__social">
                                <img style="width: 5rem;padding-bottom: 0rem;" src="../../assets/Sudani-Logo.png" alt="">
                            </div><span><small class="footer__copyright">Made by BYTE Co.ltd © 2022</small></span></div></div></div></div></footer>
        
    </center>
    <!-- end footer -->
</template>

<script>
export default {
   name: 'Footer'
}
</script>

<style>
.footerimg {
width:10%
}
@media (max-width: 390px) {
    .footerimg{width:5%;}
    
    }
</style>